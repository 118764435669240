<template>
    <LiefengModal title="授权机构" :value="modalStatus" @input="modalStatusFn" :fullscreen="true">
        <template v-slot:contentarea>
            <div class="container">
                <div class="left">
                    <Tooltip placement="top" transfer>
                        <div slot="content">
                            <p style="text-align: center; width: 180px">按组织查找</p>
                        </div>
                        <Select v-model="orgCode" style="width: 200px" placeholder="请选择维度" :label-in-value="true" filterable @on-change="changeOrgCode">
                            <Option v-for="(item, index) in orgList" :value="item.id" :key="index">{{ item.name }}</Option>
                        </Select>
                    </Tooltip>
                </div>
                <div class="main">
                    <div class="search">
                        <Input v-model="name" style="width: 200px; margin-left: 10px" placeholder="通过机构名称或编码查询"></Input>
                        <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="search">查询</Button>
                        <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="reset">重置</Button>
                        <Button style="margin-right: 10px" type="primary" @click="openAddOrg">新增机构</Button>
                        <Button style="margin-right: 10px" type="error" @click="cancelOrg" :loading="canceLoading">取消授权</Button>
                        <Button style="margin-right: 10px" type="error" @click="cancelExample" :loading="exampleLodaing">取消示范社区授权</Button>
                        <Button style="margin-right: 10px" type="error" @click="cancelunExample" :loading="unExampleLodaing">取消非示范社区授权</Button>
                    </div>
                    <LiefengTable ref="table" :talbeColumns="tableColumns" :tableData="tableData" :height="tableHeight" :loading="loading" @tableSelect="tableSelect" :hidePage="true"></LiefengTable>
                </div>
            </div>
        </template>
        <template v-slot:toolsbar>
            <Button type="primary" @click="save">确定</Button>
        </template>
    </LiefengModal>
</template>

<script>
//@route('/authorizeindex')
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            tableHeight: "",
            modalStatus: true,
            cancelModalTip: false,
            orgList: [],
            orgCode: "",
            orgCodeName: "",

            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "所属维度",
                    key: "dimensionName",
                    minWidth: 90,
                    align: "center",
                },
            ],
            tableData: [],
            loading: false,

            // 搜索
            name: "",
            // 标识选择市，区或街
            index: "",

            tableSelectArr: [],
            rightList: [],
            removeRightList: [],

            // 存放所有获取到的数据
            allTableList: [],
            canceLoading: false,
            exampleLodaing: false,
            unExampleLodaing: false,
            // 是非示范社区
            onlinOrgList:[]
        }
    },
    methods: {
        // 取消授权按钮
        cancelOrg() {
            if (!this.tableSelectArr || !this.tableSelectArr.length) {
                this.$Message.warning({
                    content: "请选择需要取消授权的机构",
                    background: true,
                })
                return
            }
            this.updateRow('canceLoading')
        },
        // 取消示范社区
        async cancelExample(){
            this.exampleLodaing = true
            await this.getonlineList(1)
            if(this.onlinOrgList.length)
            await this.updateRow('exampleLodaing',this.onlinOrgList)
        },
        // 取消非示范社区
        async cancelunExample(){
            this.unExampleLodaing = true
            await this.getonlineList(0)
            if(this.onlinOrgList.length)
            await this.updateRow('unExampleLodaing',this.onlinOrgList)
        },
        // 获取是非示范社区
        async getonlineList(orgOnlineStatus){
            await this.$get('/gx/pc/orgOnline/selectJWHByOnline',{
              orgOnlineStatus,
            }).then( res => {
                if(res.code == 200 && res.dataList.length){
                    this.onlinOrgList = []
                    res.dataList.map( item => {
                        this.onlinOrgList.push(item.orgCode)
                    })
                }else{
                    this.exampleLodaing = false
                    this.unExampleLodaing = false
                    this.$Message.warning({
                        background: true,
                        content: orgOnlineStatus?"无示范社区":"无非示范社区",
                    })
                }
            })
        },
        // 新增按钮
        openAddOrg() {
            parent.layer.open({
                type: 2,
                title: "新增机构",
                content: `/page#/authorizenewindex?id=${window.location.href.substring(window.location.href.indexOf("?") + 1).split("=")[1]}`,
                area: ["100%", "100%"],
                end: () => {
                    this.getList()
                },
            })
        },
        save() {
            var index = parent.layer.getFrameIndex(window.name) //获取当前窗口的name
            if (index) {
                parent.layer.close(index)
            }
        },
        // 表格选中
        tableSelect(data) {
            this.tableSelectArr = JSON.parse(JSON.stringify(data))
        },

        //重置
        reset() {
            this.name = ""
            this.getSameList(this.allTableList, "dimensionName", "orgCodeName")
        },
        // 查询
        search() {
            if (this.allTableList && this.allTableList.length) {
                this.getSameList(this.allTableList, "orgName", "name")
            } else {
                this.tableData = []
            }
        },
        // 机构维度改变
        changeOrgCode(item) {
            if (item) {
                this.orgCodeName = item.label
                this.name = ''
                this.getSameList(this.allTableList, "dimensionName", "orgCodeName")
            }
        },
        modalStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.modalStatus = status
                    },
                })
            } else {
                this.modalStatus = status
            }
        },
        // 获取表格数据
        async getList() {
            // 回显选中的右边列表
            this.loading = true
            try {
                await this.$get("/datamsg/api/pc/platformMenu/singleMenuGrantOrg", {
                    oemCode: window.top.vue.oemInfo.oemCode,
                    platformMenuId:window.location.href.substring(window.location.href.indexOf("?") + 1).split("=")[1],
                }).then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.allTableList = res.dataList
                        this.getSameList(this.allTableList, "dimensionName", "orgCodeName")
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
            } catch (err) {
                this.loading = false
                this.$Message.error({
                    background: true,
                    content: "获取数据失败，请联系管理员处理",
                })
            }
        },

        // 取消授权接口
        async updateRow(loadingType,orgCodes) {
            this[loadingType] = true
            try {
                await this.$post(
                    "/datamsg/api/pc/platformMenu/cancelMenuGrantOrg",
                    {
                        oemCode: window.top.vue.oemInfo.oemCode,
                        orgCodes: orgCodes || (() => {
                            let arr = []
                            this.tableSelectArr.map(item => {
                                arr.push(item.orgCode)
                            })
                            return arr
                        })(),
                        platformMenuId:window.location.href.substring(window.location.href.indexOf("?") + 1).split("=")[1],
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    this[loadingType] = false
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "取消授权成功",
                            background: true,
                        })
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: "取消授权失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch (err) {
                this[loadingType] = false
                this.$Message.error({
                    content: "取消授权失败",
                    background: true,
                })
                return
            }
        },
        // 根据条件筛选数据
        getSameList(arr, name, clear) {
            let list = []
            if (arr && arr.length) {
                if (clear == "name") {
                    if(this.orgCodeName && this.orgCodeName != ''){
                        arr.map(item => {
                            if (item[name].indexOf(this[clear]) > -1 && item.dimensionName == this.orgCodeName) {
                                list.push(item)
                            }
                        })
                    }else{
                        arr.map(item => {
                            if (item[name].indexOf(this[clear]) > -1) {
                                list.push(item)
                            }
                        })
                    }
                    
                } else {
                    arr.map(item => {
                        if (item[name] == this[clear]) {
                            list.push(item)
                        }
                    })
                }
            }
            this.tableData = list
        },
    },
    async created() {
        //获取机构维度
        await this.$get("/gx/pc/dimension/selectExceptOneByOemCode", {
            oemCode: window.top.vue.oemInfo.oemCode,
        })
            .then(res => {
                if (res.code == 200 && res.dataList) {
                    this.orgList = res.dataList.map((item, index) => {
                        if (index == 0) {
                            this.orgCode = item.id
                            this.orgCodeName = item.name
                        }
                        return {
                            id: item.id,
                            name: item.name,
                        }
                    })
                    this.changeOrgCode() //机构维度发生改变，获取表格列表
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
            .catch(err => {
                console.log(err)
                this.$Message.error({
                    background: true,
                    content: "获取数据失败，请联系管理员处理",
                })
            })

        await this.getList()
    },
    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },

    components: {
        LiefengModal,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
.container {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 123px);
    /deep/.left {
        margin: 0 10px;
        width: 200px;
        height: 100%;
        border-left: 1px solid #dcdee2;
        border-right: 1px solid #dcdee2;
        border-bottom: 1px solid #dcdee2;
        border-radius: 4px 4px 0 0;
        /deep/.ivu-select-dropdown {
            height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);
        }
    }
    .main {
        // flex: 1;
        max-width: calc(100vw - 200px - 100px);
        min-width: 600px;
        .search {
            width: 100%;
            margin-bottom: 10px;
        }
        & > div {
            width: 100%;
        }
    }
    .button {
        width: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .ivu-btn {
            margin-bottom: 16px;
        }
    }
    .right {
        min-width: 200px;
        height: 100%;
        border: 1px solid #dcdee2;
        overflow: auto;
        .rightTitle {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            line-height: 40px;
            color: #515a6e;
            background: #f8f8f9;
        }
        /deep/.active {
            background: #19be6b;
            color: #fff;
        }
    }
}
/deep/.ivu-modal-close {
    display: none;
}
</style>